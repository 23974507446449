@media screen and (min-width: 100px) and (max-width: 705px) {

}

@media screen and (min-width: 706px) {

}

.modal-save-button {
    background-color: var(--main-elements-bg-color);
    /*background-color: black;*/
    color: white;
    /*font-weight: bold;
    border-radius: 8px;
    border: 1px solid #ffffff;
    padding: 8px;
    width: 80%;*/
    border: 1px solid rgba(255, 249, 249, 0.89);
    box-sizing: border-box;

    width: 70px;
    height: 70px;
    bottom: 20px;
    right: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 50%;

    text-align: center;
    /*box-shadow: 2px 2px #9ca3af;*/
    position: absolute;
    /*bottom: 10px;
    left:0;
    right:0;
    margin-left: auto;
    margin-right: auto;*/
    cursor: pointer;
    z-index: 3;
}

.modal-save-button:active {
    transform: translate(4px, 4px);
    /*transform: translateX(2px);*/
}

.modal-save-button:hover {
    transform: translate(2px, 2px);
    /*transform: translateX(2px);*/
}

.modal-save-button-space {
    height: 50px;
}

.modal-save-button::before {
    content: '';
    position: absolute;
    border: 2px solid var(--main-elements-bg-color);
    background-color: transparent;
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    bottom: -1px;
    right: -1px;

    opacity: 1;

    border-radius: 50%;
    animation: save-button-animation 2s linear infinite;
}

@keyframes save-button-animation {
    0% {
        color: white;
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
        opacity: 0;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

